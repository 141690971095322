<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <data-table
      :data-source="sortedTraitements"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          @click.prevent="initMedoc"
          data-toggle="modal"
          data-target="#add-traitement"
          v-if="canAdd"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>
    <modal
      id="add-traitement"
      title="Ajouter un traitement"
      size="x-large"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="adding"
      >
        <div
          class="alert alert-danter"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <animal-detail :show-detail="true" />
        </div>
        
        <div class="form-group">
          <label for="maladie">Maladie</label>
          <select
            class="form-control"
            id="maladie"
            name="maladie"
            v-model="maladie"
            v-select="{placeholder: 'Selectionnez la maladie'}"
          >
            <option value="" />
            <option
              v-for="(m, i) in maladies"
              :value="m.uid"
              :key="i"
            >
              {{ m.libelle }}
            </option>
          </select>
        </div>
        <fieldset class="medicaments">
          <legend>
            Medicaments 
            <div>
              <a
                href="javascript:void(0)"
                @click.prevent="incrementMedoc"
                class="btn btn-outline-primary btn-sm"
              >
                <i class="icofont icofont-plus" />
              </a>
              <a
                href="javascript:void(0)"
                @click.prevent="decrementMedoc"
                class="btn btn-outline-third btn-sm"
              >
                <i class="icofont icofont-close" />
              </a>
            </div>
          </legend>
          <div class="medicaments">
            <add-medicaments
              v-for="i in index"
              :key="i"
              :order="i"
            />
          </div>
        </fieldset>
        <hr>
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label class="checkbox">
                <input
                  type="checkbox"
                  name="hospitalisation"
                  v-model="hospitalisation"
                >
                <span style="padding-right: 10px;" /> Hospitalisation thérapeutique
              </label>
            </div>
          </div>
          <div class="col-8">
            <div class="form-group">
              <label for="duration">Durée d'hospitalisation</label>
              <input
                type="number"
                name="duration"
                id="duration"
                class="form-control"
                v-model="duration"
              >
            </div>
          </div>
        </div>
        
        
        <div class="form-group">
          <label for="add-info">Information Supplementaires</label>
          <textarea
            name="add-info"
            id="add-info"
            rows="5"
            class="form-control"
            v-model="info"
          />
        </div>
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Ajouter
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>
  <script>
  import {mapGetters, mapMutations} from 'vuex'
  import moment from 'moment'
  import navbar from '../../../components/navbar.vue'
  import DataTable from '../../../components/dataTable/local.vue'
  import {  DATE_TYPE, COMPONENT_TYPE, TEXT_TYPE} from '../../../components/dataTable/dataType'
  import AnimalDetail from '../../../components/identification/animal/detailInform.vue'
  import Modal from '../../../components/modal.vue'
import AddMedicaments from '../../../components/sanitaire/traitement/addMedicaments.vue'
import { ADD_TRAITEMENT } from '../../../graphql/sanitaire'
  const Animal = () => import('../../../components/sanitaire/animal.vue')
  const CreatedBy = () => import('../../../components/sanitaire/createdBy.vue')
  const Medicaments = () => import('../../../components/sanitaire/traitement/medicaments.vue')
  const Maladie = () => import('../../../components/sanitaire/traitement/maladie.vue')
  const Actions = () => import('../../../components/sanitaire/traitement/actions.vue')
  export default {
      components: {navbar, DataTable, Modal, AnimalDetail, AddMedicaments },
      data(){
          return {
            navbarItems: [
              {
                  libelle: 'Sanitaire'
              },
              {
                libelle: 'Traitement',
              }
            ],
            pageIcon: 'la-prescription',
            pageTitle: 'Traitement',
            pageDescription: 'Liste des traitements',
            has_error: false,
            error_msg: null,
            maladie: null,
            info: null,
            hospitalisation: false,
            duration: null
          }
      },
      methods: {
          ...mapMutations({
              done: 'DONE',
              initMedoc: 'sanitaire/INIT_INDEX_MEDICAMENT',
              decrementMedoc: 'sanitaire/DECREMENT_INDEX_MEDICAMENT',
              incrementMedoc: 'sanitaire/INCREMENT_INDEX_MEDICAMENT'
          }),
          initForm(){
            this.maladie = null
            this.info = null
            this.initMedoc()
          },
          adding() {
            let data = {
                animal: this.selectedAnimal,
                maladie: this.maladie,
                medicaments: JSON.stringify(this.traitementMedocs),
                info: this.info,
                hospitalisation: this.hospitalisation,
                duration: parseInt(this.duration)
            }
            this.$apollo.mutate({
                mutation: ADD_TRAITEMENT,
                variables: {
                    "traitement": {
                        ...data
                    }
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Traitement sur ${this.maladie} add successfully`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
          }
      },
      computed: {
          ...mapGetters({
              selectedAnimal: 'identification/selectedAnimal',
              index: 'sanitaire/indexMedicaments',
              traitementMedocs: 'sanitaire/traitementMedocs',
              traitements: 'sanitaire/traitements',
              maladies: 'sanitaire/maladies',
              is_super_admin: 'auth/is_super_admin',
              is_veterinaire: 'auth/is_veterinaire',
              is_assistant_veterinaire: 'auth/is_assistant_veterinaire',
              is_assistant_veto_major: 'auth/is_assistant_veto_major',
          }),
          sortedTraitements(){
              return [...this.traitements].sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
          },
          headers(){
              return [
                  {label: 'ID', name: 'uid', type: TEXT_TYPE, visible: this.is_super_admin},
                  {label: 'Animal', name: 'animal', type: COMPONENT_TYPE, component: Animal},
                  {label: 'Maladie', name: 'maladie', type: COMPONENT_TYPE, component: Maladie},
                  {label: 'Médicaments', name: 'medicaments', type: COMPONENT_TYPE, component: Medicaments},
                  {label: 'Vétérinaire traitant', name: 'createBy', type: COMPONENT_TYPE, component: CreatedBy},
                  {label: 'Date de traitement', name: 'createdAt', type: DATE_TYPE, sortable: true},
                  {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
              ]
          },
          canAdd(){
            return this.is_super_admin ||
              this.is_veterinaire ||
              this.is_assistant_veto_major
          }
      }
  }
  </script>

  <style lang="scss" scoped>
  .medicaments{
        legend{
            position: relative;
            div{
                position: absolute;
                right: 0px;
                top: 0px;
                a{
                    margin-left: 5px;
                }
            }
        }
  }
  
  </style>