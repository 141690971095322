<template>
  <div class="row">
    <div class="col-3">
      <label :for="'add-fm'+order">Famille  {{ order }}</label>
      <select
        class="form-control"
        v-model="familleM"
        v-select="{placeholder: 'Selectionnez la famille de medicament'}"
        :name="'add-fm'+order"
        :id="'add-fm'+order"
      >
        <option value="" />
        <option
          v-for="(f,c) in familleMedicaments"
          :value="f.uid"
          :key="c"
        >
          {{ f.libelle }}
        </option>
      </select>
    </div>
    <div class="col-4">
      <label :for="'add-medicament'+order">Médicament {{ order }}</label>
      <select
        class="form-control"
        v-model="medicament"
        v-select="{placeholder: 'Selectionnez le médicament'}"
        :name="'add-medicament'+order"
        :id="'add-medicament'+order"
      >
        <option value="" />
        <option
          v-for="(m,c) in filtredMedicaments"
          :value="m.uid"
          :key="c"
        >
          {{ m.libelle }}
        </option>
      </select>
    </div>
    <div class="col-5">
      <label :for="'add-protocole'+order">Protocole d'administration</label>
      <input
        type="text"
        maxlength="30"
        :name="'add-protocole'+order"
        :id="'add-protocole'+order"
        v-model="protocole"
        class="form-control"
      >
    </div>
  </div> 
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    props: {order: {type: Number, required: true}},
    data(){
        return {
            protocole: "",
            familleM: null,
            medicament: null,
            filtredMedicaments: []
        }   
    },
    watch: {
      familleM(){
        this.filtredMedicaments = [...this.medicaments].filter(item => item.famille === this.familleM)
      },
        updateData:{
            handler(){
                this.removeMedoc(this.updateData.order)
                this.addMedoc(this.updateData)
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
            addMedoc: 'sanitaire/ADD_TRAITEMENT_MEDOC',
            removeMedoc: 'sanitaire/REMOVE_TRAITEMENT_MEDOC'
        })
    },
    computed: {
        ...mapGetters({
            familleMedicaments: 'sanitaire/familleMedicaments',
            medicaments: 'sanitaire/medicaments'
        }),
        updateData(){
            return {order: this.order, medoc: this.medicament, protocole: this.protocole}
        }
    }
}
</script>

<style lang="scss" scoped>
.medicaments{
    
}
</style>